import { useAmplifyAuth } from '@loggi/authentication-lib';
import ImpersonationBanner from '@loggi/authentication/src/components/impersonation-banner';
import { GOOGLE_PROVIDER } from '@loggi/authentication/src/screens/signin/constants';
import { preloadFirebaseConfigs } from '@loggi/components/src/one/remote-config';
import { Box, CircularProgress, CssBaseline } from '@mui/material';
import { captureException } from '@sentry/react';
import {
  NativeSignInErrorAlert,
  PageHelmet,
  TrackingErrorMessage
} from 'UI/components';
import { useDomainUseCase } from 'UI/contexts';
import { useCurrentUser, useUserCompany } from 'auth';
import applyGoogleTranslatorMonkeyPatch from 'configuration/apply-google-translator.monkey-patch';
import { COGNITO_ERROR_MESSAGES } from 'constants/error-messages';
import { useSnackbar } from 'hooks';
import { useSetupHotjar } from 'hooks/setup-hotjar/use-setup-hotjar.hook';
import {
  hasWebViewInterface,
  switchToNativeApp,
  useNativeAppEventHandler
} from 'mobile';
import { createSwitchToNativeEvent } from 'mobile/native-app-event';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import TagManager from 'react-gtm-module';
import { AppRouter } from 'routes';
import setSentryContext from 'sentry/sentry-context';
import { grey, root } from 'theme/colors';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';
import { useFeatureSwitch } from 'hooks/feature-switch';
import { getGeneralAppStyles } from 'utils';
import { setSafeAreaInsetTop } from '../theme/safeAreaInsetTop';

global.loggi = {
  version: process.env.REACT_APP_VERSION
};

const ZoomDisabler = () => (
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
  />
);

const App = () => {
  const [firebaseLoading, setFirebaseLoading] = useState(true);
  const [ableToAccessBeyond, setAbleToAccessBeyond] = useState(false);
  const [isError, setIsError] = useState(false);
  const showSnackbar = useSnackbar();
  const { pathname } = useLocation();
  const isUserAllowedOnBeyond = useDomainUseCase(
    'isUserAllowedOnBeyondUseCase'
  );
  const fetchCompany = useDomainUseCase('fetchCompanyUseCase');
  const user = useCurrentUser();
  const company = useUserCompany(user);
  const { data: isDisableBeyondEnabled, isFetched } = useFeatureSwitch(
    'disable_beyond'
  );
  const {
    signOut,
    federatedSignIn,
    state: { isImpersonation, loading }
  } = useAmplifyAuth();
  const {
    setLogoutHandler,
    setNativeLoginErrorHandler
  } = useNativeAppEventHandler();
  const ssoUrl = process.env.REACT_APP_SSO_URL;
  const corpUrl = process.env.REACT_APP_CORP_URL;
  const { search: searchParams } = window.location;
  const shouldSignInUserWithGoogle = searchParams?.includes(
    COGNITO_ERROR_MESSAGES.SIGN_UP_GOOGLE_USER_LINK_REDIRECT
  );
  const shouldKeepLoadingWhenBeyondIsDisabled =
    isDisableBeyondEnabled &&
    (pathname === '/' ||
      pathname === '/inicio' ||
      pathname === '/entrar' ||
      pathname.includes('cadastro'));

  useEffect(() => {
    if (shouldSignInUserWithGoogle) {
      federatedSignIn(GOOGLE_PROVIDER);
    }
  }, [shouldSignInUserWithGoogle, federatedSignIn]);

  setSafeAreaInsetTop();

  const onTryAgain = () => {
    window.location.reload();
  };

  const nativeLoginErrorHandler = useCallback(
    error => {
      showSnackbar({
        message: <NativeSignInErrorAlert error={error} />,
        severity: 'error',
        onClose: signOut
      });
      signOut();
    },
    [showSnackbar, signOut]
  );
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    /**  Remove the below line approach, which was left for legacy reasons (see {@link native-app-event-handler.context.js}) */
    setNativeLoginErrorHandler(nativeLoginErrorHandler);
  }, [nativeLoginErrorHandler]);
  useEffect(() => setLogoutHandler(signOut), [signOut]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useSetupHotjar();

  useEffect(() => {
    const cognitoUserId = user?.sub;
    if (cognitoUserId) {
      TagManager.dataLayer({ dataLayer: { cognitoUserId } });
    }
  }, [user]);

  useEffect(() => {
    const userCompanyId = company?.id;
    if (userCompanyId) {
      TagManager.dataLayer({ dataLayer: { companyId: userCompanyId } });
    }
  }, [company]);

  useEffect(() => {
    setSentryContext({ user, company });
  }, [user, company]);

  useEffect(() => {
    preloadFirebaseConfigs()
      .then(() => {
        applyGoogleTranslatorMonkeyPatch();
        setFirebaseLoading(false);
      })
      .catch(() => {
        setIsError(true);
      });
  }, []);

  const [companyId, userEmail] = [company?.id, user?.email];
  const canCheckUser = !firebaseLoading && companyId;
  const handleUserNotAllowed = useCallback(
    idToken => {
      const payload = createSwitchToNativeEvent({
        idToken,
        hasBeyondAccess: false
      });

      if (hasWebViewInterface())
        switchToNativeApp(payload).catch(nativeLoginErrorHandler);
      else
        window.location.replace(
          `${ssoUrl}?utm_source=beyond&next=${corpUrl}#t=${idToken}`
        );
    },
    [ssoUrl, corpUrl, nativeLoginErrorHandler]
  );

  useEffect(() => {
    if (loading === false && !user) return setAbleToAccessBeyond(true);
    if (loading === false && !!user && user.companyAccess.length === 0)
      return setAbleToAccessBeyond(true);
    if (!canCheckUser) return setAbleToAccessBeyond(false);

    return fetchCompany(companyId)
      .then(userCompany => {
        TagManager.dataLayer({ dataLayer: userCompany.configurationJson });
        return setAbleToAccessBeyond(true);
      })
      .catch(err => {
        setIsError(true);
        captureException(err);
      });
  }, [
    canCheckUser,
    companyId,
    fetchCompany,
    handleUserNotAllowed,
    isUserAllowedOnBeyond,
    loading,
    user,
    userEmail
  ]);

  const isMobileDevice = checkIsMobile();

  if (isError) {
    return (
      <>
        {isMobileDevice && <ZoomDisabler />}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          mx={3}
        >
          <TrackingErrorMessage onTryAgain={onTryAgain} />
        </Box>
      </>
    );
  }

  if (
    firebaseLoading ||
    !ableToAccessBeyond ||
    shouldSignInUserWithGoogle ||
    !isFetched ||
    shouldKeepLoadingWhenBeyondIsDisabled
  ) {
    return (
      <>
        <PageHelmet title="Loggi" />
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          minHeight="100vh"
        >
          <CircularProgress data-testid="firebase-preloading" />
        </Box>
      </>
    );
  }

  const {
    containerHeight,
    containerPosition,
    maxWidth,
    scrollbarWidth
  } = getGeneralAppStyles({ isMobileDevice });

  return (
    <>
      {isMobileDevice && <ZoomDisabler />}
      <CssBaseline />
      {isImpersonation && <ImpersonationBanner name={user?.name} />}
      <Box display="flex" justifyContent="center" bgcolor={grey[75]}>
        <Box
          bgcolor={root[0]}
          maxWidth={maxWidth}
          position={containerPosition}
          width="100%"
          height={containerHeight}
          className="app-root-container"
          style={{
            overflowY: 'auto',
            scrollbarWidth
          }}
        >
          <AppRouter isImpersonation={isImpersonation} />
        </Box>
      </Box>
    </>
  );
};

export default App;
