import { logNativeAnalyticsEvent } from 'mobile';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router';
import {
  signUp,
  login,
  signUpCompany,
  shipmentIndispatchSuccessV2,
  shipmentSummarySuccessV2,
  newHome
} from 'routes/routes';
import HomeVisited from 'crm/entities/events/home-visited/home-visited';
import AccountLoginViewed from 'crm/entities/events/account-login-viewed/account-login-viewed';
import AccessCompleted from 'crm/entities/events/access-completed/access-completed';
import { useFeatureSwitch } from 'hooks/feature-switch';
import { useHistory } from 'react-router-dom';
import { useQueryParams, useUTMs } from 'hooks';
import { UTM_KEYS } from 'constants/utm.constants';
import { isEqual } from 'lodash';
import { useDomainUseCase } from 'UI/contexts';

const isUrlMatching = (url, companyId) => {
  const regex = new RegExp(
    `^https://beyond\\.loggi\\.com/(v2/)?${companyId}(.*)$`
  );
  return regex.test(url);
};

const routeActions = [
  {
    path: signUp,
    action: () => logNativeAnalyticsEvent('beyond_signUpStarted')
  },
  {
    path: shipmentSummarySuccessV2,
    action: () => logNativeAnalyticsEvent('beyond_loggiFacil_orderCreated')
  },
  {
    path: shipmentIndispatchSuccessV2,
    action: () => logNativeAnalyticsEvent('beyond_indespacho_orderCreated')
  },
  {
    path: newHome,
    action: isHomeVisitedEventEnabled => {
      if (isHomeVisitedEventEnabled) new HomeVisited().sendToCrm();
    }
  },
  {
    path: login,
    action: isLoginViewedEventEnabled => {
      if (isLoginViewedEventEnabled) new AccountLoginViewed().sendToCrm();
    }
  }
];

const handleActualRoute = (
  path,
  isHomeVisitedEventEnabled,
  isLoginViewedEventEnabled
) => {
  const matchedRoute = routeActions.find(route => matchPath(path, route.path));
  if (matchedRoute) {
    switch (matchedRoute.path) {
      case newHome:
        matchedRoute.action(isHomeVisitedEventEnabled);
        break;
      case login:
        matchedRoute.action(isLoginViewedEventEnabled);
        break;
      default:
        matchedRoute.action();
        break;
    }
  }
};

const isAtEntryRoute = (path, search) => {
  const query = new URLSearchParams(search);
  const isFromProvider = query.has('code') && query.has('state');
  return (
    matchPath(path, login) || matchPath(path, signUpCompany) || isFromProvider
  );
};

const RouteChangesObserver = () => {
  const getCurrentUser = useDomainUseCase('getCurrentUserUseCase');
  const { pathname, search } = useLocation();
  const [isEntryRouteAccessed, setIsEntryRouteAccessed] = useState(false);
  const { data: isHomeVisitedEventEnabled, isFetched } = useFeatureSwitch(
    'enable_crm_event_home_visited'
  );
  const {
    data: isLoginViewedEventEnabled,
    isFetched: isLoginEventFSFetched
  } = useFeatureSwitch('enable_crm_event_login_viewed');

  const { data: isDisableBeyondEnabled } = useFeatureSwitch('disable_beyond');

  const history = useHistory();
  const utmsHook = useUTMs();
  const savedUTMs = utmsHook.getValue();
  const queryParams = useQueryParams();

  const utmFromURL = Object.fromEntries(
    Object.entries(queryParams).filter(([key]) => UTM_KEYS.includes(key))
  );

  const isUTMParamsEmpty = Object.keys(utmFromURL).length === 0;

  const setUTMParams = useCallback(() => {
    if (!isUTMParamsEmpty) {
      if (!isEqual(savedUTMs, utmFromURL)) {
        utmsHook.setValue(utmFromURL);
      }
      return;
    }

    if (savedUTMs) {
      history.replace({
        pathname,
        search: new URLSearchParams({ ...queryParams, ...savedUTMs }).toString()
      });
    }
  }, [
    isUTMParamsEmpty,
    savedUTMs,
    utmFromURL,
    utmsHook,
    history,
    pathname,
    queryParams
  ]);

  useEffect(() => {
    setUTMParams();
  }, [setUTMParams]);

  useEffect(() => {
    if (isDisableBeyondEnabled) {
      if (
        pathname === '/' ||
        pathname === '/inicio' ||
        pathname === '/entrar' ||
        pathname.includes('cadastro')
      ) {
        window.location.replace(
          `https://app.loggi.com${window.location.search}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisableBeyondEnabled]);

  useEffect(() => {
    const checkUrlMatch = async () => {
      const currentUser = await getCurrentUser();
      const companyId = currentUser?.companyId;
      if (!companyId) return;
      if (isUrlMatching(window.location.href, companyId))
        window.location.replace('https://app.loggi.com');
    };

    checkUrlMatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetched && isLoginEventFSFetched) {
      handleActualRoute(
        pathname,
        isHomeVisitedEventEnabled,
        isLoginViewedEventEnabled
      );
    }
  }, [
    pathname,
    isHomeVisitedEventEnabled,
    isFetched,
    isLoginEventFSFetched,
    isLoginViewedEventEnabled
  ]);

  useEffect(() => {
    const handleHomeAccessFromEntryRoutes = () => {
      if (!isEntryRouteAccessed) {
        if (isAtEntryRoute(pathname, search)) setIsEntryRouteAccessed(true);
      } else if (matchPath(pathname, newHome)) {
        new AccessCompleted().sendToCrm();
        setIsEntryRouteAccessed(false);
      }
    };
    handleHomeAccessFromEntryRoutes();
  }, [pathname, isEntryRouteAccessed, search]);

  return <></>;
};

export default RouteChangesObserver;
